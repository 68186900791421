import { EnvTypes } from "myria-core-sdk";

const ENV_CORE_SDK = process.env.REACT_APP_SDK_ENV;

function getEnvTypes() {
  console.log("ENV SDK -> ", ENV_CORE_SDK);
  if (ENV_CORE_SDK === "DEV") {
    return EnvTypes.DEV;
  } else if (ENV_CORE_SDK === "STAGING") {
    return EnvTypes.STAGING;
  } else if (ENV_CORE_SDK === "PREPROD") {
    return EnvTypes.PREPROD;
  } else if (ENV_CORE_SDK === "PROD") {
    return EnvTypes.PRODUCTION;
  }
  return EnvTypes.STAGING;
}

const env = getEnvTypes();
const accessKeyId = "AKIAWFBB5VYXXVN46FNO";
const secretAccessKey = "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";
const bucketName = `admin-tools-assets-${env}`;
const region = "us-east-1";

export { env, accessKeyId, secretAccessKey, bucketName, region };
